@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: IBM Plex Sans, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

.App {
  width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


svg { background: transparent; }

.site-layout-content {
  background: #FFFFFF;
  padding: 24px;
  position:relative;
  min-height: 50vh;
  padding-bottom: 10%;
}

// General Theme Configs
.hortivar-input {
    height: 40px;
    margin-top: 20px;
    background: #FFFFFF;
    border: 0.5px solid #B5AEA8 !important;
    box-sizing: border-box;
    border-radius: 12px !important;

    mix-blend-mode: normal;
    .ant-input:placeholder-shown {
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      /* or 136% */

      display: flex;
      align-items: center;
      text-indent: 10px;

      color: #666666;
    }
}

.spinner {
  margin: 150px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.statistics {
  min-height: 1000px;
}

.cultivars-dropdown-final {
  width: 40%;
  height: 40px;
  margin-top: 5%;
  background: white;
  border: 1px solid #9b9895;
  box-sizing: border-box;
  border-radius: 8px;
  .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    span{
      padding-top: 1% !important;
      padding-left: 2% !important;
    }
  }
}

.cultivars-dropdown-40 {
  width: 49%;
  height: 40px;
  margin-top: 5%;
  background: white;
  border: 1px solid #9b9895;
  box-sizing: border-box;
  border-radius: 8px;
  .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    span{
      padding-top: 1% !important;
      padding-left: 2% !important;
    }
  }
}

.cultivars-dropdown-40-right {
  width: 49%;
  height: 40px;
  float: right;
  margin-top: 5%;
  background: white;
  border: 1px solid #9b9895;
  box-sizing: border-box;
  border-radius: 8px;
  .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    span{
      padding-top: 1% !important;
      padding-left: 2% !important;
    }
  }
}


.cultivars-dropdown-final-100 {
  width: 100%;
  height: 40px;
  margin-top: 5%;
  background: white;
  border: 1px solid #9b9895;
  box-sizing: border-box;
  border-radius: 8px;
  .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    span{
      padding-top: 1% !important;
      padding-left: 1% !important;
    }
  }
}

.cultivars-dropdown-light-100 {
  width: 100%;
  height: 40px;
  margin-top: 5%;
  background: white;
  border: 1px solid #DBD2CA !important;
  box-sizing: border-box;
  border-radius: 5px;
  .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    span{
      padding-top: 1% !important;
      padding-left: 1% !important;
    }
  }
}

.p-bold {
  font-weight: bold;
}

.search-filters {
  .tag {
    background: #FFC801;
    border: 1px solid #B5AEA8;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 14px;
    height: 30px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #5D5E5E;
    font-weight: bold;
    margin-bottom: 5px;
    span {
      font-weight: normal !important;
    }
  }
}

.search-pane {
  padding-left: 5%;
  padding-right: 5%;
}

// Footer Decoration
#footer-flowers {
  background-image: url(./assets/footer.png);
  position: absolute;
  padding: 20px;
  height: 114px;
  margin-left: 90px;
  margin-right: 90px;
  background-repeat: repeat-x;
  width: 1160px;
  transform: translateY(-70%);
}

#top-footer {
  background-color: #E9E4DF;
}

/* Header */

.ant-layout-header {
  background-color: #206BA9 !important;
  height: 80px !important;

  .ant-menu-horizontal {
      border-bottom: transparent !important;

      .ant-menu-item a {
        color: white !important;
      }
  }

  .logo {
    height: 40px;
    width: 240px;
    margin-top: 18px;
    margin-left: 50px;
    float: left;
  }
}

.menu-nav {
  background-color: #206BA9 !important;
  min-height: 72px !important;
  float: right;
}

.navlinks {
  color: white !important;
}

.right-nav-header {
  padding-top: 10px;
  color: white !important;
  font-size: 11px !important;
  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: white !important;
  }
  .ant-btn-link {
    color: white !important;
    font-size: 11px !important;
  }
  .anticon-logout {
    color: white !important;
  }
}

.left-nav-header {
  padding-top: 12px;
  margin-right: 35px !important;
  color: white !important;
  font-size: 11px !important;
}

.ant-menu-horizontal {
  padding-top: 6px !important;
  .ant-menu-submenu {
    margin: 0 0 !important;
  }
  .ant-menu-item-selected a {
    color: white !important;
  }
}

/* Header End */

// Sub Nav
.subnav {
  background-color: #DBD2CA;
  left: 100px;
  right: 100px;
  color: #DBD2CA;
  padding-top: 25px;
  padding-bottom: 25px;
  .logo {
    width: 153px;
    height: 65.15px;
    margin-left: 40%;
  }

  .subnav-links {
    .nav-sub {
      float: right;
      line-height: 10px;
      position: absolute;
      top: 50%;
      left: 25%;
      -ms-transform: translate(-50%, -30%);
      transform: translateY(-50%);
      line-height: 20px;
      a {
        padding: 10px;
        align-items: center;
        color: #381D11 !important;
        justify-content: space-around;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }

  .subnav-dropdown {
    position: absolute;
    top: 50%;
    left: 25%;
    -ms-transform: translate(-50%, -30%);
    transform: translateY(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #381D11;

    span {
      padding-left: 10px;
    }
  }

  .submit-entry {
    .ant-btn:hover, .ant-btn:focus {
      color: #fff !important;
      background: #EC8103 !important;
      border-color: #EC8103 !important;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
    }
  }
  .submit-entry-btn {
    background: #EC8103;
    border-color:  #EC8103;
    mix-blend-mode: normal;
    border-radius: 10px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: white;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    span {
      font-size: 14px;
    }
  }
}

.comment-section {
  margin-right: 20px;
}

.subnav-search {
  margin-right: 30px;
  padding-top: 40px;
  width: 100%;
  .subnav-search-bar {
    //position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 12px !important;
    width: 480px;
    .ant-input-search-button {
      border-radius: 0px 12px 12px 0px !important;
      background-color: #EC8103;
      border-color: #EC8103 !important;
      width: 40px;
      height: 40px;
    }
    .ant-input-group-addon {
      mix-blend-mode: normal;
      border-radius: 12px 0px 0px 12px !important;
      font-style: normal;
      line-height: 20px;
      color: #381D11;
      font-weight: 500;
      background: transparent !important;
    }

    .ant-input-group-addon:first-child {
      width: 141px;
      height: 40px;
      text-align: left;
      padding-left: 3% !important;
      color: #381D11 !important;
      background: #EFEBE8 !important;
    }

    .ant-input-group-addon .ant-btn:hover, .ant-btn:focus {
      color: #fff !important;
      background: #EC8103 !important;
      border-color: #EC8103 !important;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
    }

    .ant-input-search-enter-button + .ant-input-group-addon {
      background-color: transparent !important;
    }

    .ant-input-group .ant-input {
      font-size: 12px;
    }

    .ant-input-group-addon:not(:first-child):not(:last-child), .ant-input-group-wrap:not(:first-child):not(:last-child), .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
      border-radius: 0;
      height: 40px;
    }
  }
}

.search-result-p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 14px;
  /* or 82% */

  display: flex;
  align-items: center;

  color: #B5AEA8;

  span {
    color: black;
    font-weight: bold;
    padding-left: 10px !important;
  }
}

// Sub Nav End

/* Footer */

.ant-layout-footer {
  background: white !important;
}

.ant-layout-content {
  background-color: #E9E4DF;
}

/* SubNav */

.is-active {
  background: #DCD5CF;
  color: #535353;
  font-weight: 500;
  border-radius: 12px;
  width: 180px;
  height: 40px;
  left: 497px;
  top: 90px;
}

/* 404 Not Found */

.not-found-error {
  font-size: 100px;
  margin-block-start: auto;
  margin-block-end: auto;
  font-weight: 700;
  color: #A0968E;
  padding-top: 30%;
  margin: 0;
  font-size: 100px;
  /* or 14% */
  letter-spacing: -0.02em;
  line-height: 110%;
}

.not-found-search {
  font-size: 55px;
  margin-block-start: auto;
  margin-block-end: auto;
  font-weight: 700;
  color: #A0968E;
  padding-top: 30%;
  margin: 0;
  /* or 14% */
  letter-spacing: -0.02em;
  line-height: 110%;
}

.p-search-text {
  font-size: 13px;
  font-weight: normal;
  color: #A0968E;
  padding: 0;
  margin: 0;
  line-height: 20px;
}

.p-not-found-text {
  font-size: 14px;
  font-weight: 500;
  color: #A0968E;
  padding: 0;
  margin: 0;
  line-height: 20px;
}

.error-pane {
  height: 63vh !important;
  background: url(../src/assets/error.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

/* Search Bar */

.mid-content {
  padding-top: 10%;
  padding-bottom: 10%;
}

.search-cultivar {
  padding: 10px 15px !important;
  font-size: 20px;
  border-radius: 12px !important;
}

/* Menu Items */

.ant-menu-item {
  color: white !important;
  text-decoration: none !important;
}

.ant-menu-item-selected:hover {
  text-decoration: none !important;
}

.ant-menu-item:hover {
  text-decoration: none !important;
  margin: 10px;
}

/* Footer */

.footer {
  background-color: #827163 !important;
  padding-left: 10% !important;
  padding-right: 10% !important;
  padding-top: 3% !important;
  padding-bottom: 2% !important;
  background-position: center center !important;
  display: block;

  .footer-version {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .footer-links {
    a {
      color: #E9E4DF;
      font-style: normal;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .footer-social {
    p {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      font-weight: bold;
      color: #FFFFFF;
      font-size: 12px;
      line-height: 10px;
      span {
        font-size: large;
      }
    }
  }
}

/* Content */

// .content {
//   margin-bottom: 10%;
// }


// Cultivars Page
.create-cultivar-right {
  padding: 30px;
  margin-top: 2% !important;
  .create-cultivar-modal {
    text-align: left;
    height: 40px;
    width: 100%;
    background: #DCD5CF;
    mix-blend-mode: normal;
    border-radius: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #535353;
    border-color: #DCD5CF !important;
    margin-bottom: 20px;
    .anticon {
      color: #C4210A;
      font-weight: bold;
  }
  }

  .create-cultivar-modal:hover, .create-cultivar-modal:focus {
    color: #fff !important;
    background: rgba(220,213,207,1) !important;
  }
}

.create-cultivar {
  padding-bottom: 15%;
  .create-cultivar-save-btn {
    float: right !important;
    height: 40px;
    background: #32620B;
    border-color: #32620B;
    border-radius: 12px;
    mix-blend-mode: normal;
    color: white;
  }
  .create-cultivar-save-btn:hover, .create-cultivar-save-btn:focus {
    background: #32620B;
    border-color: #32620B;
    color: white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
  }

  .create-cultivar-save-btn-prev {
    float: right !important;
    height: 40px;
    background: #A39C96;
    border-color: #A39C96;
    border-radius: 12px;
    mix-blend-mode: normal;
    color: white;
  }
  .create-cultivar-save-btn-prev:hover, .create-cultivar-save-btn-prev:focus {
    background: #A39C96;
    border-color: #A39C96;
    color: white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
  }

  .create-cultivar-save-btn-final {
    float: right !important;
    height: 40px;
    background: #EC8103;
    border-color: #EC8103;
    border-radius: 12px;
    mix-blend-mode: normal;
    color: white;
  }
  .create-cultivar-save-btn-final:hover, .create-cultivar-save-btn-final:focus {
    background: #EC8103;
    border-color: #EC8103;
    color: white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
  }
}

.steps-action {
  margin-top: -6% !important;
}

.cultivars-container {
  margin-top: 6% !important;
  margin-bottom: 10% !important;
  background-color: #DCD5CF !important;
  border-color: #DCD5CF !important;
  border-radius: 12px !important;
  padding-bottom: 5% !important;
  min-height: 450px;

  .search-span {
    margin-top: 20px;
    font-size: 10px;
    line-height: 19px;
    text-indent: 10px;
    padding-left: 10px;
    color: #535353;

  }

  .ant-select-selector {
    cursor: text;
    border-radius: 8px !important;
    width: 50%;
    background: #FFFFFF;
    border: transparent !important;
    box-sizing: border-box;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #535353;
    margin-top: 20px;
  }

  .header-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #535353;
    span {
      float: right;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      /* or 211% */

      color: #356B0C;
    }
  }

  .ant-progress-inner {
    width: 31px !important;
    height: 31px !important;
    .ant-progress-text {
      font-style: normal;
      font-weight: bolder;
      font-size: 10px;
      line-height: 30px;
      /* or 333% */
      align-items: center;
      text-align: center;
      letter-spacing: -0.06em;

      color: #356B0C;

      opacity: 0.5;
    }
  }

  .cultivars-dropdown {
    width: 50%;
    height: 40px;
    margin-top: 20px;
    background: #FFFFFF;
    border: 1px solid #9b9895;
    box-sizing: border-box;
    border-radius: 8px !important;
    span {
      float: left;
      font-size: 14px;
    }
    .anticon {
      float: right;
      padding-top: 1.5%;
    }
  }

  .cultivars-dropdown-final {
    width: 50%;
    height: 40px;
    margin-top: 5%;
    background: #FFFFFF;
    border: 1px solid #9b9895;
    box-sizing: border-box;
    border-radius: 8px;
    .ant-select-selector {
      border-color: transparent !important;
      box-shadow: none !important;
      span{
        padding-top: 2% !important;
        padding-left: 2% !important;
      }
    }
  }

  .cultivars-input-50 {
    height: 40px;
    width: 50%;
    background: #FFFFFF;
    border: 0.5px solid #9b9895 !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    mix-blend-mode: normal;

    .ant-input:placeholder-shown {
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      /* or 136% */

      display: flex;
      align-items: center;
      color: #666666;
    }
  }

  .input-side-text {
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #666666;
      padding-left: 2%;
    }
  }

  .cultivars-input {
    height: 40px;
    margin-top: 20px;

    background: #FFFFFF;
    border: 1px solid #9b9895 !important;
    box-sizing: border-box;
    border-radius: 8px !important;

    mix-blend-mode: normal;

    .ant-input:placeholder-shown {
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      /* or 136% */

      display: flex;
      align-items: center;
      text-indent: 10px;

      color: #666666;
    }
  }
}

.advanced-search-double {
  .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
    width: 40% !important;
  }
}

.advanced-search-results {
  margin-top: -5px;
  .no-data {
    margin-top: 36px;
  }
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;

  background: #FFFFFF;
  border: none !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.cultivar-sidebar {
  margin-top: 3% !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #535353;

  span:hover {
    color:  #535353 !important;
  }

  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.2em;
    color: #C4210A !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #C4210A !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #535353;
  }
}

.cultivar-popover {
  .ant-modal-content {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
    border-radius: 12px !important;
  }

  .ant-modal-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
}

.date-range-inputs {
  width: 68px !important;
  height: 40px !important;

  background: #FFFFFF !important;
  border: 1px solid #9B9895 !important;
  box-sizing: border-box !important;
  border-radius: 12px !important;
}

// Search Results
.search-results-sidebar {
  .search-results-filter-pane {
    border: 1px solid #B5AEA8;
    border-radius: 12px;
    padding: 25px;
  }
  .search-results-filter-btn {
    mix-blend-mode: normal;
    border: 1px solid #B5AEA8;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    /* or 93% */
    color: #4E493F;
  }
  .search-results-photos {
    margin-right: 15px;
    p{
      padding-left: 20px;
      span {
        font-weight: bold;
      }
    }
    .ant-form-item {
      margin-bottom: 10px !important;
    }
    .search-results-image {
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      .ant-image-img {
        border-radius: 12px;
        width: 100%; /* width of container */
        height: 180px; /* height of container */
        object-fit: cover;
      }
    }
  }
}

.search-results-table {
  .ant-table-thead > tr > th {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 11px !important;
    line-height: 11px !important;
    color: #333333 !important;
  }
}

.search-results-main {
  .ant-table-tbody > tr > td {
    height: 30px !important;
    font-style: normal !important;
    font-size: 11px !important;
    line-height: 11px !important;
    color: #333333 !important;
    border-bottom: 1px solid #DCD5CF;
  }
}

.search-result-breadcrumb {
  font-weight: normal;
  font-size: 11px !important;
  line-height: 20px;
  float: right;
  display: flex;
  padding: 20px;
  align-items: center;

  /* line grey */
  color: #535353;
  .overlays {
    font-weight: bold;
    font-size: 12px !important;
  }

  .ant-breadcrumb-link {
    font-size: 11px !important;
  }
}

.search-results-map {
  border: 1px solid #9B9895 !important;
  box-sizing: border-box !important;
  border-radius: 10px;
}

// Login Panel
.login-panel {
  .login-copyright {
    position: relative;
    z-index: 1000;
    float: right;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    padding-right: 20px;
    color: #FFFFFF;
  }
  margin-top: 5%;
  .login-panel-right {
    padding-left: 15%;
  }

  .login-panel-left {
    .login-description {
      span {
        font-weight: bold;
      }
      padding-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #6D6B68;
    }
  }

  .ant-image-img {
    flex-shrink: 0;
    min-width: 100% !important;
    min-height: 50% !important;
  }

  .cultivars-input {
    height: 40px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 0.5px solid #6A6561 !important;
    box-sizing: border-box;
    border-radius: 12px !important;
    mix-blend-mode: normal;
    .ant-input:placeholder-shown {
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      /* or 136% */
      display: flex;
      align-items: center;
      text-indent: 10px;
      color: #666666;
    }
  }


  .forgot-password-p {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #666666;
    float: right;
  }

  .login-panel-image {
    .ant-image {
      height: 400px;
      object-fit: cover;
    }

    .ant-carousel .slick-slide img {
      border-radius: 30px 30px 0px 0px !important;
    }
  }


  .login-panel-bottom {
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 2%;
    padding-top: 50px !important;
    background: #E9E4DF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    .register-btn {
      float: right;
      background: #E9E4DF;
      mix-blend-mode: normal;
      height: 40px;
      border: 1px solid #6D6B68;
      box-sizing: border-box;
      border-radius: 12px;
    }

    .ant-btn-primary {
      color: #fff;
      float:right;
      height: 40px;
      border-color: #EC8103 !important;
      background: #EC8103 !important;
      mix-blend-mode: normal;
      border-radius: 12px;
    }
  }
}

.registration-panel {
  margin-top: 5%;
  .registration-panel-top {
    padding-top: 2%;
    padding-left: 5%;
    padding-bottom: 1%;
    background: #206BA9;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .registration-panel-bottom {
    padding-right: 10%;
    padding-left: 4%;
    padding-bottom: 5%;
    padding-top: 20px !important;
    background: #E9E4DF;
    // box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    .submission-buttons {
      width: 40%;
    }

    .hortivar-gray-btn {
      float: left !important;
    }

    .input-text-top {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #6D6B68;
    }

    h3 {
      font-style: normal;
      margin-top: 15%;
      font-weight: 300;
      font-size: 22px;
      line-height: 19px;
      color: #6D6B68;
    }

    .logo {
      margin-top: 2%;
      margin-bottom: 10%;
      padding-right: 30%;
      svg {
        float: left !important;
        height: 50px;
      }
    }

    .register-btn {
      float: right;
      background: #E9E4DF;
      mix-blend-mode: normal;
      height: 40px;
      border: 1px solid #6D6B68;
      box-sizing: border-box;
      border-radius: 12px;
    }

    .hortivar-orange-btn {
      height: 40px;
      float: right;
      background: #EC8103;
      border-color: #EC8103;
      border-radius: 8px;
      mix-blend-mode: normal;
      color: white;
    }
    .hortivar-orange-btn:hover, .chortivar-orange-btn:focus {
      background: #EC8103;
      border-color: #EC8103;
      color: white;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
    }

    .hortivar-gray-btn {
      height: 40px;
      background: #989591 !important;
      border-color: #989591 !important;
      border-radius: 8px;
      margin-right: 10px;
      mix-blend-mode: normal;
      color: white;
    }
    .hortivar-gray-btn:hover, .hortivar-gray-btn:focus {
      background: #989591 !important;
      border-color: #989591 !important;
      color: white;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
    }
  }
}

// General Styles
.cultivars-input-40 {
  height: 40px;
  width: 40% !important;
  margin-right: 10px !important;
  background: #FFFFFF;
  margin-bottom: 20px !important;
  border: 0.5px solid #6A6561 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  mix-blend-mode: normal;

  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-indent: 10px;
    color: #666666;
  }
}

// View Cultivar Record
#view-cultivar-left-panel {
  margin-right: 20px;
  p {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4E493F
  }

  .cultivar-detail-card {
    width: 200px;
    height: 120px;
    border-radius: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);

    .card-detail-left {
      padding: 10px;
      background: #CE2B1E;
      height: 120px !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .crop-name {
        font-weight: bold;
        font-size: 13px;
      }

      p {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: #FFFFFF;

      }
    }
    .card-detail-right {
      padding: 10px;
      p {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 12px;
        align-items: center;
        color: #666666;
      }
    }
  }
}

.user-information-panel {
  margin-top: 40px;
  text-align: center;
  .user-avatar {
    background: #E9E4DF;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 10px;
  }
  .user-fullname {
    margin-left: 40px;
    padding-top: 15px;
    text-align: center !important;
  }
}

.tableau-timelines {
  p {
    font-size: 13px !important;
  }
  margin-top: 40px;
  .tableau-date-range {
    margin-left: 10px;
    font-weight: 300;
  }
}

.url-embeds {
  padding: 20px;
  background: #E2DCD7;
  mix-blend-mode: normal;
  border-radius: 10px;
  margin-top: 40px;
}

// Load data error.
.data-load-error {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  text-align: center !important;
  color: #C4210A;
  line-height: 13px;
  border: 1px solid #C4210A;
  border-radius: 4px;
  padding: 15px;
  span {
    margin-top: 10px !important;
    text-align: center;
  }
}

// Cultivar Information Tab Component
.cultivar-information-tabs {
  .ant-tabs-content {
    float: right;
  }
  .ant-tabs-tab {
    margin: none !important;
    padding: 12px 24px 12px 0px !important;
  }
  .ant-divider-dashed {
      background: none;
      border: 1px dashed #53535345 !important;
      border-style: dashed;
  }
  .ant-tabs-nav-wrap {
    margin-top: 30px;
  }
  .ant-tabs-tabpane {
    background-color: #E9E4DF;
    min-height: 540px;
    border-radius: 12px;
    padding: 30px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    align-items: center;

    color: #535353;
    span {
      font-weight: 600;
    }
  }
  .ant-divider-horizontal {
    margin: 12px 0;
  }
  .ant-tabs-tab-btn {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    /* or 93% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    /* line grey */
    color: #535353;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold !important;
    color: #535353 !important;
  }
}

// Good Morning Hortivar.
.gmh-submit-post-btn {
  background: #827163 !important;
  border-color: #827163 !important;
  mix-blend-mode: normal !important;
  border-radius: 10px !important;
  width: 180px;
  height: 40px !important;
  float: right;
  color: white !important;
  margin-right: 25px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  color: #FFFFFF;
  mix-blend-mode: normal;
}

.gmh-submit-comment-btn {
  background: #EC8103 !important;
  mix-blend-mode: normal !important;
  border-radius: 10px !important;
  //width: 112px;
  height: 40px !important;
  float: right;
  border-color: #EC8103 !important;
  color: white !important;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  color: #FFFFFF;
  mix-blend-mode: normal;
}

.advanced-search-btn {
  background: #E9E4DF !important;
  border-radius: 10px !important;
  height: 40px !important;
  float: right;
  border-color: #E9E4DF !important;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  margin-right: 10px;
}

.gmh-submit-comment-btn:hover, .gmh-submit-comment-btn:focus {
  border-color: #EC8103 !important;
}

.gmh-submit-post-btn:hover, .gmh-submit-post-btn:focus {
  color: white !important;
  border-color: #827163 !important;
}

.gmh-header {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  /* or 82% */
  padding-left: 20px;
  display: flex;
  align-items: center;

  color: #4E493F;

}

.gmh-post-card {
  width: 510px;
  height: 240px;
  margin-right: 20px;
  // margin-left: 20px;
  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  /* or 13px */
  margin-bottom: 40px;
  color: #000000;

  .next-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    position: absolute;
    margin-right: 20px;
    margin-bottom: 20px;
    right: 0;
    bottom: 0;
  }

  .left-info {
    padding: 20px;
    background: #E9E4DF;
    mix-blend-mode: normal;
    border-radius: 12px 0px 0px 12px;
    height: 240px;
    .names {
      p {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 110%;
        /* or 12px */
        color: #4E493F;

      }
      span {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 110%;
        /* or 11px */
        display: flex;
        align-items: center;
        color: #4E493F;
      }
    }
    .location {
      position: absolute;
      bottom: 0;
      margin-bottom: 20px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 110%;
      /* or 12px */

      text-indent: 2px;
    }
  }

  .right-info {
    padding: 20px;

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 110%;
      color: #000000;
      padding-right: 40px;
    }
  }
}

.round-label {
  font-style: normal;
  border-radius: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  height: 20px;
  text-indent: 2px;
  width: 90px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center !important;
  color: #5D5E5E;
  background: #E9E4DF;
  margin-right: 10px;
}

.round-label-full {
  font-style: normal;
  border-radius: 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-indent: 2px;
  max-width: 136px !important;
  padding: 3px 20%;
  text-align: center !important;
  color: #5D5E5E;
  background: #E9E4DF;
  margin-right: 10px;
}

.hash-label {
  border-radius: 14px;
  color: #5D5E5E;
  background: #FFC801;
}

// Create Post GMH
#post-question {
  .post-question-title {
    background: #FFFFFF;
    border: 1px solid #9B9895;
    box-sizing: border-box;
    border-radius: 8px;
    height: 50px;
    margin-bottom: 20px;
  }

  .post-question-buttons {
    margin-top: 20px;
    .first {
      background: #B3AFAB !important;
      border-radius: 6px !important;
      width: 100% !important;
      margin-right: 0 !important;
    }

    .second {
      border-radius: 6px !important;
      width: 100% !important;
      margin-right: 0 !important;
    }

    .third {
      background: #EC8103 !important;
      border-radius: 6px !important;
      width: 100% !important;
      margin-right: 0 !important;
    }

  }

  .post-question-textfield {
    textarea.ant-input {
      max-width: 100%;
      background: #FFFFFF;
      border: 1px solid #9B9895;
      box-sizing: border-box;
      border-radius: 8px;
      height: 310px !important;
    }
  }
}

.gmh-post-left {
  padding-left: 20px;
  .author-card {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 160px;
    mix-blend-mode: normal;
    border-radius: 12px;
    padding: 20px;
    background: #827163;
  }
  .label {
    width: 136px !important;
  }
}

.author-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  color: #FFFFFF;
}

.author-points {
  padding-bottom: 10px;
  font-size: 11px;
  line-height: 110%;
  align-items: center;
  color: #FFFFFF;
}

.author-bottom {
  font-size: 13px;
  line-height: 110%;
  margin-top: auto;
  color: #FFFFFF;
  bottom: 0;
}

.author-logo {
  color: white !important;
  bottom: 0;
  position: absolute;
  margin-bottom: 10px;
}

.title-two {
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #4E493F;
}

.title-three {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-indent: 2px;
  color: #5D5E5E;
}

.question-card {
  padding-right: 20px;
  .question-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    max-width: content-box;
    color: #000000;
  }
  .question-text {
    font-size: 12px;
    line-height: 110%;
    color: #000000;
  }
  .submit-comment-btn {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0% !important;
    float: right !important;

    background: #EC8103 !important;
    mix-blend-mode: normal;
    border-radius: 10px !important;
    color: white !important;
    border-color: #EC8103 !important;
  }
}

.gray-card {
   background: #E9E4DF !important;
  .author-name {
    color: #4E493F !important;
  }
  .author-bottom, .author-points {
    color: #4E493F !important;
  }
}

.question-text {
  font-size: 12px;
  line-height: 110%;
  color: #000000;
}

.comments {
  margin-top: 40px;
  padding-right: 20px;
}

.comments-alert {
  margin-top: 0px;
  padding-right: 20px;
}

.votes {
  font-size: 12px;
  line-height: 90%;
  color: #5D5E5E;
  padding-left: 20px;
  padding-bottom: 20px;
}

.post-comment-textfield {
  margin-bottom: 15px;
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 150px !important;
  }
}

.submit-answer-modal {
  .ant-modal-footer {
    padding: 5px 16px 30px;
    text-align: right;
    background: transparent;
    border-top: none !important;
    border-radius: 0 0 2px 2px;
    button, html [type="button"], [type="reset"], [type="submit"] {
      border-radius: 6px !important;
      width: 141px;
      height: 40px;
    }

    .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      background: #E56204 !important;
      border-color: #E56204 !important;
    }
  }
}

.gmh-time {
  float: right;
}

.answer-header {
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  color: #5D5E5E;
}

.gmh-h2 {
  font-size: 18px;
  line-height: 18px;
  color: #4E493F;
  margin-top: 40px;
  margin-bottom: 20px;
}

.cultivar-h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: #535353;
}


.ant-dropdown {
  background-color: #EFEBE8 !important;
  padding: 10px !important;
  left: 506px;
  top: 120px;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  // line-height: 22px;
  a {
    color: #381D11 !important;
  }

  a::active {
    color: white !important;
  }

  .active {
    font-weight: bold !important;
  }
}

// View Cultivar Additional Im

// GMH Improvements
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  max-width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 310px !important;
}

.nutritional-information {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: #535353;
    span {
      float: right !important;
    }
  }

  .ant-collapse-content-box {
    background-color: #E9E4DF !important;
  }

  .ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    border-top: 1px red #d9d9d9 !important;
  }

  .ant-collapse-header {
    background-color: #E2DCD7 !important;
    font-weight: bold !important;
  }
}

.usda-image {
  text-align: center !important;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #817163 !important;
  border-color: #817163 !important;
  border-radius: 4px !important;

  a {
    color: #ffffff !important;
  }
}

.page-header {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 14px;
  color: #B5AEA8;
}

.gmh-profile-logo {
  border-radius: 30px;
}

.profile-information {
  .name-tag {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px !important;
  }
}

.gravatar-icon {
  border-radius: 10px;
  border: 1px #E9E4DF solid;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3)
}

// Search Dropdown
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

// Hortivar About
.about-page {
  margin-top: 30px;
  .about-logo {
    margin-bottom: 50px;
  }
  .about-hortivar-p-bold {
    /* FAO of the UN Hortivar (AGPML) Viale delle Terme di Caracalla 00153, Rome, Italy hortivar@fao.org 003906-570 56347 */
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* or 119% */
    align-items: center;
    color: #000000;
  }
  .contact-right {
    .cultivar-h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
    }
    .about-hortivar-p {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      /* or 19px */
      color: #000000;
    }
    .tabbed-space {
      margin-left: 20px;
    }
  }
}

.about-links {
  a {
    font-size: 14px;
    line-height: 13px;
    /* or 93% */
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #535353;
  }
  a:active {
    color: #535353 !important;
    font-weight: bold !important;
  }

  a:visited {
    color: #535353 !important;
    font-weight: bold !important;
  }

  a:hover {
    color: #535353 !important;
  }
}

.species-latin-name {
  font-size: 12px;
  color: #32620B;
}
